export const emitter = mitt();

export class global_params {
  static regions = [];
  static region_indexes = {};
  static region_boundaries = null;
  static cities = [];
  static city_indexes = {};
  static districts = [];
  static district_indexes = {};
  static district_boundaries = null;
  static months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  static ages = {
    1	 :  "New",
    2	 :  "LessThanYear",
    3	 :  "OneYear",
    4	 :  "TwoYears",
    5	 :  "ThreeYears",
    6	 :  "FourYears",
    7	 :  "FiveYears",
    8	 :  "SixYears",
    9	 :  "SevenYears",
    10 :  "EightYears",
    11 :  "NineYears",
    12 :  "TenYears",
    13 :  "MoreThenTenYears",
  }
  static legend_icons = {
    "Apartment": "/assets/maalek/images/map-icons/apartment-icon-l.png",
    "Studio": "/assets/maalek/images/map-icons/apartment-icon-l.png",
    "Room": "/assets/maalek/images/map-icons/apartment-icon-l.png",
    "Floor": "/assets/maalek/images/map-icons/floor-icon-l.png",
    "Villa": "/assets/maalek/images/map-icons/villa-icon-l.png",
    "School": "/assets/maalek/images/map-icons/villa-icon-l.png",
    "HospitalOrHealthCenter": "/assets/maalek/images/map-icons/villa-icon-l.png",
    "Building": "/assets/maalek/images/map-icons/building-icon-l.png",
    "Compound": "/assets/maalek/images/map-icons/building-icon-l.png",
    "Tower": "/assets/maalek/images/map-icons/building-icon-l.png",
    "Hotel": "/assets/maalek/images/map-icons/building-icon-l.png",
    "Chalet": "/assets/maalek/images/map-icons/chalet-icon-l.png",
    "RestHouse": "/assets/maalek/images/map-icons/chalet-icon-l.png",
    "Farm": "/assets/maalek/images/map-icons/farm-icon-l.png",
    "Land": "/assets/maalek/images/map-icons/land-icon-l.png",
    "TelecomTower": "/assets/maalek/images/map-icons/land-icon-l.png",
    "ElectricityStation": "/assets/maalek/images/map-icons/land-icon-l.png",
    "Teller": "/assets/maalek/images/map-icons/land-icon-l.png",
    "Shop": "/assets/maalek/images/map-icons/shop-icon-l.png",
    "Booth": "/assets/maalek/images/map-icons/shop-icon-l.png",
    "RepairShop": "/assets/maalek/images/map-icons/shop-icon-l.png",
    "Office": "/assets/maalek/images/map-icons/shop-icon-l.png",
    "Warehouses": "/assets/maalek/images/map-icons/warehouse-icon-l.png",
    "Station": "/assets/maalek/images/map-icons/warehouse-icon-l.png",
    "Factory": "/assets/maalek/images/map-icons/warehouse-icon-l.png",
    "CarParking": "/assets/maalek/images/map-icons/warehouse-icon-l.png",
    "Cinema": "/assets/maalek/images/map-icons/warehouse-icon-l.png",
    "Exhibition": "/assets/maalek/images/map-icons/warehouse-icon-l.png",
  };
  static map_icons = {
    "Default": {
      "Rent": "/assets/maalek/images/map-icons/default-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/default-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/default-icon-o.png",
    },
    "Apartment": {
      "Rent": "/assets/maalek/images/map-icons/apartment-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/apartment-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/apartment-icon-o.png",
    },
    "Studio": {
      "Rent": "/assets/maalek/images/map-icons/apartment-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/apartment-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/apartment-icon-o.png",
    },
    "Room": {
      "Rent": "/assets/maalek/images/map-icons/apartment-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/apartment-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/apartment-icon-o.png",
    },
    "Floor": {
      "Rent": "/assets/maalek/images/map-icons/floor-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/floor-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/floor-icon-o.png",
    },
    "Villa": {
      "Rent": "/assets/maalek/images/map-icons/villa-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/villa-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/villa-icon-o.png",
    },
    "School": {
      "Rent": "/assets/maalek/images/map-icons/villa-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/villa-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/villa-icon-o.png",
    },
    "HospitalOrHealthCenter": {
      "Rent": "/assets/maalek/images/map-icons/villa-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/villa-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/villa-icon-o.png",
    },
    "House": {
      "Rent": "/assets/maalek/images/map-icons/house-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/house-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/house-icon-o.png",
    },
    "Building": {
      "Rent": "/assets/maalek/images/map-icons/building-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/building-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/building-icon-o.png",
    },
    "Compound": {
      "Rent": "/assets/maalek/images/map-icons/building-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/building-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/building-icon-o.png",
    },
    "Tower": {
      "Rent": "/assets/maalek/images/map-icons/building-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/building-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/building-icon-o.png",
    },
    "Hotel": {
      "Rent": "/assets/maalek/images/map-icons/building-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/building-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/building-icon-o.png",
    },
    "Chalet": {
      "Rent": "/assets/maalek/images/map-icons/chalet-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/chalet-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/chalet-icon-o.png",
    },
    "RestHouse": {
      "Rent": "/assets/maalek/images/map-icons/chalet-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/chalet-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/chalet-icon-o.png",
    },
    "Camp": {
      "Rent": "/assets/maalek/images/map-icons/camp-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/camp-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/camp-icon-o.png",
    },
    "Farm": {
      "Rent": "/assets/maalek/images/map-icons/farm-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/farm-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/farm-icon-o.png",
    },
    "Land": {
      "Rent": "/assets/maalek/images/map-icons/land-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/land-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/land-icon-o.png",
    },
    "TelecomTower": {
      "Rent": "/assets/maalek/images/map-icons/land-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/land-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/land-icon-o.png",
    },
    "ElectricityStation": {
      "Rent": "/assets/maalek/images/map-icons/land-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/land-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/land-icon-o.png",
    },
    "Teller": {
      "Rent": "/assets/maalek/images/map-icons/land-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/land-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/land-icon-o.png",
    },
    "Shop": {
      "Rent": "/assets/maalek/images/map-icons/shop-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/shop-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/shop-icon-o.png",
    },
    "Booth": {
      "Rent": "/assets/maalek/images/map-icons/shop-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/shop-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/shop-icon-o.png",
    },
    "RepairShop": {
      "Rent": "/assets/maalek/images/map-icons/shop-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/shop-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/shop-icon-o.png",
    },
    "Office": {
      "Rent": "/assets/maalek/images/map-icons/shop-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/shop-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/shop-icon-o.png",
    },
    "Warehouses": {
      "Rent": "/assets/maalek/images/map-icons/warehouse-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/warehouse-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/warehouse-icon-o.png",
    },
    "Station": {
      "Rent": "/assets/maalek/images/map-icons/warehouse-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/warehouse-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/warehouse-icon-o.png",
    },
    "Factory": {
      "Rent": "/assets/maalek/images/map-icons/warehouse-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/warehouse-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/warehouse-icon-o.png",
    },
    "CarParking": {
      "Rent": "/assets/maalek/images/map-icons/warehouse-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/warehouse-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/warehouse-icon-o.png",
    },
    "Cinema": {
      "Rent": "/assets/maalek/images/map-icons/warehouse-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/warehouse-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/warehouse-icon-o.png",
    },
    "Exhibition": {
      "Rent": "/assets/maalek/images/map-icons/warehouse-icon-g.png",
      "Sale": "/assets/maalek/images/map-icons/warehouse-icon-b.png",
      "Auction": "/assets/maalek/images/map-icons/warehouse-icon-o.png",
    },
  };
  static ad_type_colors = {
    Rent: "#4CBB17",
    Sale: "#4d89ff",
    Auction: "#ff9000",
  };

  constructor() {}
  static async get_regions() {
    if (global_params.regions.length > 0) return global_params.regions;
    try {
      emitter.emit("show-full-loading");
      let params = [];
      let res = await $.ajax({
        headers: { "X-Frappe-CSRF-Token": this.csrf_token },
        url: "/api/method/maalek.api.get_regions",
        type: "GET",
        data: params, //for GET request
        // data: JSON.stringify(params) //for POST request
      });
      for (let index = 0; index < res.message.length; index++) {
        var region = res.message[index];
        global_params.regions.push(region);
        global_params.region_indexes[region.region_id] = index;
      }
      // emitter.emit("update-property-form");
      emitter.emit("hide-full-loading");
      return global_params.regions;
    } catch (error) {
      console.log("error");
      console.log(error);
      emitter.emit("hide-full-loading");
      return [];
    }
  }
  static get_region(region_id) {
    let region = global_params.regions[global_params.region_indexes[region_id]];
    return region;
  }
  static async get_cities(region_id = null) {
    // if(global_params.cities.length > 0)
    //   return global_params.cities
    global_params.cities = [];
    try {
      emitter.emit("show-full-loading");
      let params = {};
      if (region_id) {
        params["region_id"] = region_id;
      }
      let res = await $.ajax({
        headers: { "X-Frappe-CSRF-Token": this.csrf_token },
        url: "/api/method/maalek.api.get_cities",
        type: "GET",
        data: params, //for GET request
        // data: JSON.stringify(params) //for POST request
      });
      for (let index = 0; index < res.message.length; index++) {
        var city = res.message[index];
        global_params.cities.push(city);
        global_params.city_indexes[city.city_id] = index;
      }
      // emitter.emit("update-property-form");
      emitter.emit("hide-full-loading");
      return global_params.cities;
    } catch (error) {
      console.log("error");
      console.log(error);
      emitter.emit("hide-full-loading");
      return [];
    }
  }
  static get_city(city_id) {
    let city = global_params.cities[global_params.city_indexes[city_id]];
    return city;
  }
  static async get_districts(city_id) {
    // if(global_params.districts.length > 0)
    //   return global_params.districts
    global_params.districts = [];
    try {
      emitter.emit("show-full-loading");
      let params = {};
      let res = await $.ajax({
        headers: { "X-Frappe-CSRF-Token": this.csrf_token },
        url: "/api/method/maalek.api.get_districts?city_id=" + city_id,
        type: "GET",
        data: params, //for GET request
        // data: JSON.stringify(params) //for POST request
      });
      for (let index = 0; index < res.message.length; index++) {
        var district = res.message[index];
        global_params.districts.push(district);
        global_params.district_indexes[district.district_id] = index;
        // console.log(district.center);
      }
      // emitter.emit("update-property-form");
      emitter.emit("hide-full-loading");
      return global_params.districts;
    } catch (error) {
      console.log("error");
      console.log(error);
      emitter.emit("hide-full-loading");
      return [];
    }
  }
  static get_district(district_id) {
    let district =
      global_params.districts[global_params.district_indexes[district_id]];
    return district;
  }
  static async get_region_boundaries(region_id) {
    global_params.region_boundaries = null;
    try {
      emitter.emit("show-full-loading");
      let params = {};
      let res = await $.ajax({
        headers: { "X-Frappe-CSRF-Token": this.csrf_token },
        url:
          "/api/method/maalek.api.get_region_boundaries?region_id=" + region_id,
        type: "GET",
        data: params, //for GET request
        // data: JSON.stringify(params) //for POST request
      });
      global_params.region_boundaries = res.message.boundaries;
      emitter.emit("hide-full-loading");
      if (res.message == "") return null;
      return res.message.boundaries;
    } catch (error) {
      console.log("error");
      console.log(error);
      emitter.emit("hide-full-loading");
      return null;
    }
  }
  static async get_district_boundaries(district_id) {
    global_params.district_boundaries = null;
    try {
      emitter.emit("show-full-loading");
      let params = {};
      let res = await $.ajax({
        headers: { "X-Frappe-CSRF-Token": this.csrf_token },
        url:
          "/api/method/maalek.api.get_district_boundaries?district_id=" +
          district_id,
        type: "GET",
        data: params, //for GET request
        // data: JSON.stringify(params) //for POST request
      });
      global_params.district_boundaries = res.message.boundaries;
      emitter.emit("hide-full-loading");
      if (res.message == "") return null;
      return res.message.boundaries;
    } catch (error) {
      console.log("error");
      console.log(error);
      emitter.emit("hide-full-loading");
      return null;
    }
  }
}

export class image_file {
  data = null;
  file = null;
  link = null;
  link_name = null;
  constructor(options = {}) {
    this.data = options.data || null;
    this.file = options.file || null;
    this.link = options.link || null;
    this.link_name = options.link_name || null;
  }
}

// export const quick_cart = new cart_obj();
export const property_constants = {
  property_keys: [
    "Land",
    "Apartment",
    "Villa",
    "Shop",
    "Floor",
    "Building",
    "Office",
    "RestHouse",
    "Chalet",
    "Warehouses",
    "Studio",
    "Room",
    "Compound",
    "Tower",
    "Exhibition",
    "Booth",
    "Cinema",
    "Hotel",
    "CarParking",
    "RepairShop",
    "Teller",
    "Factory",
    "School",
    "HospitalOrHealthCenter",
    "ElectricityStation",
    "TelecomTower",
    "Station",
    "Farm",
  ],
  property_types: {
    "Apartment": [
      "video",
      "for_type",
      "location",
      "is_family",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_private_entrance",
      "is_two_entrances",
      "is_inside_villa",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_living",
      "no_bath",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Studio": [
      "video",
      "for_type",
      "location",
      "is_family",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_private_entrance",
      "is_two_entrances",
      "is_inside_villa",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_bath",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Room": [
      "video",
      "for_type",
      "location",
      "is_family",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_private_entrance",
      "is_two_entrances",
      "is_inside_villa",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Floor": [
      "video",
      "for_type",
      "location",
      "is_family",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_private_entrance",
      "is_two_entrances",
      "is_inside_villa",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_living",
      "no_bath",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Villa": [
      "video",
      "location",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_internal_stairs",
      "is_driver_room",
      "is_maid_room",
      "is_pool",
      "is_fire_room",
      "is_yard",
      "is_basement",
      "is_duplex",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_living",
      "no_bath",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description"
    ],
    "School": [
      "video",
      "location",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_internal_stairs",
      "is_driver_room",
      "is_maid_room",
      "is_pool",
      "is_fire_room",
      "is_yard",
      "is_basement",
      "is_duplex",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_bath",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description"
    ],
    "HospitalOrHealthCenter": [
      "video",
      "location",
      "is_furnished",
      "is_kitchen",
      "is_rooftop",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "is_internal_stairs",
      "is_driver_room",
      "is_maid_room",
      "is_pool",
      "is_fire_room",
      "is_yard",
      "is_basement",
      "is_duplex",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_bath",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description"
    ],
    "Building": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "no_apartment",
      "no_shop",
    ],
    "Compound": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "is_parking",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Tower": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "no_apartment",
      "no_shop",
    ],
    "Hotel": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "is_parking",
      "is_elevator",
      "is_air_conditioners",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "no_apartment",
      "no_shop",
    ],
    "RestHouse": [
      "video",
      "location",
      "is_kitchen",
      "is_pool",
      "is_fire_room",
      "is_football_area",
      "is_volleyball_area",
      "is_amusement_area",
      "is_female_section",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_living",
      "no_bath",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Chalet": [
      "video",
      "location",
      "is_kitchen",
      "is_pool",
      "is_fire_room",
      "is_football_area",
      "is_volleyball_area",
      "is_amusement_area",
      "is_female_section",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "no_bed",
      "size",
      "front",
      "side",
      "no_living",
      "no_bath",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Farm": [
      "video",
      "location",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "size",
      "front",
      "side",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "age",
    ],
    "Land": [
      "video",
      "location",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "age",
    ],
    "Teller": [
      "video",
      "location",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "age",
    ],
    "ElectricityStation": [
      "video",
      "location",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "age",
    ],
    "TelecomTower": [
      "video",
      "location",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "property_for",
      "street_width",
      "size",
      "front",
      "side",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
      "age",
    ],
    "Shop": [
      "video",
      "location",
      "is_furnished",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Booth": [
      "video",
      "location",
      "is_furnished",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "RepairShop": [
      "video",
      "location",
      "is_furnished",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Office": [
      "video",
      "location",
      "is_furnished",
      "is_inside_building",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Warehouses": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Exhibition": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Cinema": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "CarParking": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Factory": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
    "Station": [
      "video",
      "location",
      "is_furnished",
      "allow_whatsapp",
      "allow_call",
      "publish",
      "street_width",
      "size",
      "front",
      "side",
      "floor_num",
      "age",
      "price_sqr_meter",
      "total_price",
      "region",
      "city",
      "district",
      "description",
    ],
  },
  ad_types: ["Rent", "Sale"],
  property_with_child: {
    Building: ["Apartment", "Shop / Office"],
    Villa: ["Apartment"],
  },
  remove_field: {
    Rent: ["total_price"],
    Sale: ["rent_type"],
    Auction: ["rent_type"],
  },
  remove_child_field: [
    "is_sewage",
    "is_water",
    "is_power",
    "is_parking",
    "is_elevator",
    "is_internal_stairs",
    "is_football_area",
    "is_volleyball_area",
    "is_amusement_area",
    "is_private_entrance",
    "is_two_entrances",
    "is_inside_villa",
    "is_inside_building",
    "location",
    "floor_num",
    "street_width",
    "is_pool",
    "is_yard",
    "no_apartment",
    "no_shop",
    "age",
    "allow_whatsapp",
    "allow_call",
    "publish",
  ],
  remove_parent_field: [
    "is_furnished",
    "is_kitchen",
    "is_rooftop",
    "is_air_conditioners",
    "is_driver_room",
    "is_maid_room",
    "is_pool",
    "is_fire_room",
    "is_yard",
    "is_basement",
    "is_duplex",
    "rent_type",
    "no_bed",
    "size",
    "front",
    "side",
    "no_living",
    "no_bath",
    "price_sqr_meter",
    "total_price",
    "no_apartment",
    "no_shop",
  ],
  rega_features: ["is_sewage", "is_water", "is_power", "is_fixed_phone","is_fiber_optics","is_flood_drainage",],
  section_features: [
    "is_sewage",
    "is_water",
    "is_power",
    "is_fixed_phone",
    "is_fiber_optics",
    "is_flood_drainage",
    "is_family",
    "is_furnished",
    "is_kitchen",
    "is_rooftop",
    "is_parking",
    "is_elevator",
    "is_air_conditioners",
    "is_internal_stairs",
    "is_driver_room",
    "is_maid_room",
    "is_pool",
    "is_fire_room",
    "is_yard",
    "is_basement",
    "is_duplex",
    "is_football_area",
    "is_volleyball_area",
    "is_amusement_area",
    "is_female_section",
    "is_private_entrance",
    "is_two_entrances",
    "is_inside_villa",
    "is_inside_building",
  ],
  section_details: [
    "street_width",
    "no_bed",
    "no_living",
    "no_bath",
    "age",
    "size",
    "front",
    "side",
    "no_apartment",
    "no_shop",
  ],
};

export class Floor {
  num = 0;
  title = "";
  children = {};
}

export class Property {
  name = null;
  creation = null;
  property_type = "";
  ad_type = "";
  rega_data = "";
  ad_license_number = "";
  is_sewage = true;
  is_water = true;
  is_power = true;
  is_furnished = false;
  is_kitchen = false;
  is_rooftop = false;
  is_parking = false;
  is_elevator = false;
  is_air_conditioners = false;
  is_internal_stairs = false;
  is_driver_room = false;
  is_maid_room = false;
  is_pool = false;
  is_fire_room = false;
  is_yard = false;
  is_basement = false;
  is_duplex = false;
  is_football_area = false;
  is_volleyball_area = false;
  is_amusement_area = false;
  is_female_section = false;
  is_private_entrance = false;
  is_two_entrances = false;
  is_inside_villa = false;
  is_inside_building = false;
  is_fixed_phone = false;
  is_fiber_optics = false;
  is_flood_drainage = false;
  allow_whatsapp = true;
  allow_call = true;
  publish = true;
  for_type = "Both";
  is_family = false;
  rent_type = "Annually";
  property_for = "Both";
  street_width = 4;
  creator_type = "Owner";
  view_sides = [];
  no_bed = 1;
  no_apartment = 0;
  no_shop = 0;
  size = "";
  front = "";
  side = "";
  no_living = 0;
  no_bath = 0;
  floor_num = 0;
  age = 0;
  price_sqr_meter = "";
  total_price = "";
  title = "";
  // address = "";
  lat = "";
  lng = "";
  region = "";
  city = "";
  district = "";
  prv_region = "";
  prv_city = "";
  prv_district = "";
  description = "";
  // linked_images=[];
  // linked_images_names=[];
  video = null;
  images = [];
  main_image_index = 0;
  floors = []; //of object type Floor()
  errors = [];
  for_agency = 0;
  selected_agency = "";
  for_children = false;

  from_map(map_data, is_child = false) {
    if (!is_child && map_data.searchable == 0) {
      this.for_children = true;
    }
    this.name = map_data.name;
    this.rega_data = map_data.rega_data;
    this.creation = map_data.creation;
    this.property_type = map_data.property_type;
    this.ad_type = map_data.ad_type;
    this.ad_license_number = map_data.ad_license_number;
    this.is_family = map_data.is_family;
    this.is_sewage = map_data.is_sewage;
    this.is_water = map_data.is_water;
    this.is_power = map_data.is_power;
    this.is_furnished = map_data.is_furnished;
    this.is_kitchen = map_data.is_kitchen;
    this.is_rooftop = map_data.is_rooftop;
    this.is_parking = map_data.is_parking;
    this.is_elevator = map_data.is_elevator;
    this.is_fixed_phone = map_data.is_fixed_phone;
    this.is_fiber_optics = map_data.is_fiber_optics;
    this.is_flood_drainage = map_data.is_flood_drainage;
    this.is_air_conditioners = map_data.is_air_conditioners;
    this.is_internal_stairs = map_data.is_internal_stairs;
    this.is_driver_room = map_data.is_driver_room;
    this.is_maid_room = map_data.is_maid_room;
    this.is_pool = map_data.is_pool;
    this.is_fire_room = map_data.is_fire_room;
    this.is_yard = map_data.is_yard;
    this.is_basement = map_data.is_basement;
    this.is_duplex = map_data.is_duplex;
    this.is_football_area = map_data.is_football_area;
    this.is_volleyball_area = map_data.is_volleyball_area;
    this.is_amusement_area = map_data.is_amusement_area;
    this.is_female_section = map_data.is_female_section;
    this.is_private_entrance = map_data.is_private_entrance;
    this.is_two_entrances = map_data.is_two_entrances;
    this.is_inside_villa = map_data.is_inside_villa;
    this.is_inside_building = map_data.is_inside_building;
    this.allow_whatsapp = map_data.allow_whatsapp == 1 ? true : false;
    this.allow_call = map_data.allow_call == 1 ? true : false;
    this.publish = map_data.published == 1 ? true : false;
    this.rent_type = map_data.rent_type;
    this.for_type = map_data.for_type;
    this.is_family =
      this.for_type == "Family" || this.for_type == "Both" ? true : false;
    this.property_for = map_data.property_for;
    this.street_width = map_data.street_width;
    this.creator_type = map_data.creator_type;
    this.view_sides = map_data.view_sides;
    this.no_bed = map_data.no_bed;
    this.no_apartment = map_data.no_apartment;
    this.no_shop = map_data.no_shop;

    this.size = map_data.size;
    this.front = map_data.front;
    this.side = map_data.side;

    this.no_living = map_data.no_living;
    this.no_bath = map_data.no_bath;
    this.floor_num = map_data.floor_num;
    this.age = map_data.age;
    this.price_sqr_meter = map_data.price_sqr_meter;
    this.total_price = map_data.total_price;
    // this.address = "";
    this.title = map_data.title;
    this.description = map_data.description;

    this.lat = map_data.lat;
    this.lng = map_data.lng;

    // this.region = map_data.region;
    // this.city = map_data.city;
    // this.district = map_data.district;

    this.prv_region = map_data.region;
    this.prv_city = map_data.city;
    this.prv_district = map_data.district;

    this.region = "";
    this.city = "";
    this.district = "";

    this.video = map_data.video;
    this.main_image_index = 0;
    this.images = [];
    for (let index = 0; index < map_data.images.length; index++) {
      if (map_data.images[index] == map_data.image) {
        this.main_image_index = index;
      }
      this.images.push(
        new image_file({
          link: map_data.images[index],
          link_name: map_data.images_names[index],
        })
      );
    }
    this.floors = [];
    if (!is_child) {
      for (const floor_no in map_data.floors) {
        var tmp_floor = {
          children: {},
        };
        // tmp_floor.children = {};
        for (const child_property_index in map_data.floors[floor_no].children) {
          var tmp_child_property =
            map_data.floors[floor_no].children[child_property_index];
          if (
            tmp_floor.children[tmp_child_property.property_type] == undefined
          ) {
            tmp_floor.children[tmp_child_property.property_type] = [];
          }
          var tmp_property = new Property();
          tmp_property.from_map(tmp_child_property, true);
          tmp_floor.children[tmp_child_property.property_type].push(
            tmp_property
          );
        }
        this.floors.push(tmp_floor);
      }
    }
    // for (let floor_index = 0; floor_index < map_data.floors.length; floor_index++) {
    //   var data_floor = map_data.floors[floor_index];
    //   var tmp_floor = {}
    //   tmp_floor.children = {}; //of object type Floor()
    //   for (let property_type_index = 0; property_type_index < data_floor.children.property_types; property_type_index++) {
    //     var property_type_str = data_floor.children.property_types[property_type_index];
    //     var tmp_property_type = [];
    //     tmp_property_type = []
    //     for (let child_index = 0; child_index < data_floor.children.property_types[property_type_str]; child_index++) {
    //       var tmp_child = new Property();
    //       tmp_child.from_map(data_floor.children.property_types[property_type_str][child_index]);
    //       tmp_property_type[property_type_str].push(tmp_child);
    //     }
    //     tmp_floor.children[property_type_str] = ;
    //   }
    //   this.floors.push(tmp_floor);
    // }
    this.errors = [];

    this.selected_agency = "";
    this.for_agency = false;

    // var tmp_child_property = new Property();
    // tmp_child_property.from_map(this.edit_property);
  }

  optimize_images(){
    console.log("optimize_images");
    for (let index = 0; index < this.images.length; index++) {
      console.log(this.images[index].file);
      var image = this.images[index].data;
      var canvas=document.createElement("canvas");
      var context=canvas.getContext("2d");
      const width = 400; // set your width
      const scaleFactor = width / image.width;
      canvas.width = width;
      canvas.height = image.height * scaleFactor;
      console.log(image.width);
      console.log(image.height);
      context.drawImage(image, 0, 0, width, image.height * scaleFactor);
      var small_image = canvas.toDataURL("image/jpeg");
      console.log(small_image);
    }
  }

  change_location({ region_id = null, city_id = null, district_id = null }) {
    if (region_id != null) {
      this.prv_region = this.region;
      this.region = region_id;
    }
    if (city_id != null) {
      this.prv_city = this.city;
      this.city = city_id;
    }
    if (district_id != null) {
      this.prv_district = this.district;
      this.district = district_id;
    }
    this.propegate_fields();
  }

  propegate_fields() {
    for (const floor_no in this.floors) {
      for (const child_property_type in this.floors[floor_no].children) {
        for (const property_index in this.floors[floor_no].children[
          child_property_type
        ]) {
          this.floors[floor_no].children[child_property_type][
            property_index
          ].ad_type = this.ad_type;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].age = this.age;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].creator_type = this.creator_type;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].floor_num = floor_no;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].is_sewage = this.is_sewage;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].is_water = this.is_water;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].is_power = this.is_power;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].is_elevator = this.is_elevator;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].is_parking = this.is_parking;
          // this.floors[floor_no].children[child_property_type][property_index].address = this.address;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].lat = this.lat;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].lng = this.lng;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].region = this.region;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].city = this.city;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].district = this.district;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].allow_whatsapp = this.allow_whatsapp;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].allow_call = this.allow_call;
          this.floors[floor_no].children[child_property_type][
            property_index
          ].publish = this.publish;
        }
      }
    }
    emitter.emit("update-property-form");
  }
  clone_floor(floor_index) {
    var from_floor = this.floors[floor_index];
    var new_floor = new Floor();
    // new_floor.num = this.floors.length;
    for (const property_type in from_floor.children) {
      new_floor.children[property_type] = [];
      for (const child_property in from_floor.children[property_type]) {
        var property = from_floor.children[property_type][child_property];
        var new_property_clone = Object.assign(
          Object.create(Object.getPrototypeOf(property)),
          property
        );
        new_property_clone.main_image_index = 0;
        new_property_clone.title = property.title;
        new_property_clone.images = [];
        new_floor.children[property_type].push(new_property_clone);
      }
    }
    this.floors.push(new_floor);
    this.set_floors();
    return new_floor;
  }
  move_floor(floor_index, is_up) {
    if (is_up && floor_index <= 0) return;
    if (!is_up && floor_index >= this.floors.length - 1) return;
    var move = 1;
    if (is_up) {
      move = -1;
    }
    var tmp_floor = this.floors[floor_index + move];
    // var tmp_floor_num = tmp_floor.num;
    // tmp_floor.num = this.floors[floor_index].num;
    // this.floors[floor_index].num = tmp_floor_num;
    this.floors[floor_index + move] = this.floors[floor_index];
    this.floors[floor_index] = tmp_floor;
    this.set_floors();
  }
  set_floors() {
    for (const floor_no in this.floors) {
      var selected_floor = this.floors[floor_no];
      selected_floor.num = floor_no;
      for (const property_type in selected_floor.children) {
        for (const child_property in selected_floor.children[property_type]) {
          selected_floor.children[property_type][child_property].floor_num =
            floor_no;
        }
      }
    }
    console.log("set_floors");
    console.log(this.floors);
  }
  delete_child_property(floor_index, property) {
    var property_type = property.property_type;
    var property_index =
      this.floors[floor_index].children[property_type].indexOf(property);
    if (property_index < 0) return;
    this.floors[floor_index].children[property_type].splice(property_index, 1);
  }
  duplicate_child_property(floor_index, property) {
    var property_type = property.property_type;
    var property_index =
      this.floors[floor_index].children[property_type].indexOf(property);
    var new_property = Object.assign(
      Object.create(Object.getPrototypeOf(property)),
      property
    );
    new_property.images = [];
    this.floors[floor_index].children[property_type].push(new_property);
  }
  add_child_property(floor_index, property_type) {
    var floor = this.floors[floor_index];
    if (floor.children[property_type] == undefined)
      floor.children[property_type] = [];
    let new_child_property = new Property();
    new_child_property.property_type = property_type;
    new_child_property.ad_type = this.ad_type;
    floor.children[property_type].push(new_child_property);
    this.propegate_fields();
    return new_child_property;
  }
  transfer_child_property(from_floor_index, to_floor_index, property) {
    var property_type = property.property_type;
    var property_index =
      this.floors[from_floor_index].children[property_type].indexOf(property);

    if (this.floors[to_floor_index].children[property_type] == undefined) {
      this.floors[to_floor_index].children[property_type] = [];
    }
    this.floors[to_floor_index].children[property_type].push(property);
    this.floors[from_floor_index].children[property_type].splice(
      property_index,
      1
    );
    property.floor_num = to_floor_index;
  }
  validate_property(property_to_test, is_child = false) {
    property_to_test.errors = [];
    // if (property_to_test.images.length == 0)
    //   property_to_test.errors.push("main_image_index");
    if (property_to_test.errors.length > 0) {
      return false;
    }
    return true;
  }
  validate() {
    var result = true;
    if (!this.validate_property(this)) {
      result = false;
    }
    return result;
  }
}

String.prototype.format = function (args) {
  let formatted = this;
  for (let i = 0; i < args.length; i++) {
    let regexp = new RegExp("\\{" + i + "\\}", "gi");
    formatted = formatted.replace(regexp, t(args[i]));
  }
  return formatted;
};

export const search_filters = {
  region: "",
  city: "",
  district: "",
  ad_type: "",
  property_type: "",
  min_price: 0,
  max_price: 100,
  price: [0, 0],
  capacity: [0, 0],
  no_bath: 0,
  no_bed: 0,
  published: -1,
  locked: 0,
  liked: 0,
  status: null,
  sort_by: "",
  features: {},
  location: null,
  // location: {
  //   center: [26.34269026355592, 43.950194707533655],
  //   zoom: 11,
  // },
};

search_filters.features = {};
for (
  let index = 0;
  index < property_constants.section_features.length;
  index++
) {
  search_filters.features[property_constants.section_features[index]] = false;
}

export function reset_filters(
  selected_search_filters = null,
  only_features = false
) {
  if (selected_search_filters == null) {
    selected_search_filters = search_filters;
  }
  if (!only_features) {
    selected_search_filters.region = "";
    selected_search_filters.city = "";
    selected_search_filters.district = "";
    selected_search_filters.ad_type = "";
    selected_search_filters.property_type = "";
    selected_search_filters.min_price = 0;
    selected_search_filters.max_price = 100;
    selected_search_filters.price = [0, 0];
    selected_search_filters.capacity = [0, 0];
    selected_search_filters.no_bath = 0;
    selected_search_filters.no_bed = 0;
    selected_search_filters.published = -1;
    selected_search_filters.locked = 0;
    selected_search_filters.liked = 0;
    selected_search_filters.status = null;
    selected_search_filters.sort_by = "";
  }
  selected_search_filters.features = {};
  for (
    let index = 0;
    index < property_constants.section_features.length;
    index++
  ) {
    selected_search_filters.features[
      property_constants.section_features[index]
    ] = false;
  }
}

export function refresh_tooltips(){
  setTimeout(()=>{
      console.log("refresh_tooltips");
      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
          delete  tooltipTriggerEl.dataset.bsToggle
          return new bootstrap.Tooltip(tooltipTriggerEl)
      });
  }, 500);
}
